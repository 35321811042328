//const baseKey = window.config.REACT_APP_PUBLIC_URL;
const baseKey = 'ogma-knowledge-center';

export const getLocalStorageItem = (key) => {
  return localStorage.getItem(`${baseKey}-${key}`);
};

export const setLocalStorageItem = (key, value) => {
  return localStorage.setItem(`${baseKey}-${key}`, value);
};

export const removeLocalStorageItem = (key) => {
  return localStorage.removeItem(`${baseKey}-${key}`);
};
