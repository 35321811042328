import classNames from 'classnames';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Text } from '../../components/shared';
import Popup from '../../components/shared/popup';
import { StudentsProfileItem } from '../../components/shared/templates';
import { useEffect } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import PopupForm from './popupForm';

const StudentsGrid = () => {
  const isLoggedIn = !!useSelector((state) => state.auth?.uid);

  const [isExpandedStudents, setIsExpandedStudents] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [studentsData, setStudentsData] = useState({});

  useEffect(() => {
    const db = getDatabase();
    const starCountRef = ref(db, 'students');
    onValue(starCountRef, (snapshot) => {
      let result = snapshot.val();
      setStudentsData(result || []);
    });
  }, []);

  return (
    <>
      {isOpen && (
        <Popup
          title="Add a Student"
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <PopupForm
            onClose={() => {
              setIsOpen(false);
            }}
          />
        </Popup>
      )}
      <div
        id="students"
        className={classNames('cover', { expand: isExpandedStudents || Object.keys(studentsData).length <= 7 })}
      >
        <Text dataSize="x-large" dataWeight="700" dataColor="accent" dataWord="break-word">
          Community.Students.Desc
        </Text>
        <space></space>

        <grid data-type="general" data-student>
          {isLoggedIn && (
            <item data-type="add" data-position="relative" data-border="dotted">
              <div
                className="button preview"
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                <icon medium="" light="">
                  add
                </icon>
              </div>
            </item>
          )}
          {Object.keys(studentsData).map((key) => {
            const item = studentsData[key];
            item.key = key;

            return <StudentsProfileItem data={item} key={key} />;
          })}
        </grid>
      </div>
      <space></space>
      <space></space>
      {Object.keys(studentsData).length > 7 && (
        <group data-justify="center">
          <div
            className="expand"
            onClick={() => {
              setIsExpandedStudents(!isExpandedStudents);
            }}
          >
            <text data-size="medium" expand="" data-weight="700">
              {isExpandedStudents ? <Text>Community.ShowLess</Text> : <Text>Community.ShowMore</Text>}
            </text>
          </div>
        </group>
      )}
      <space></space>
      <space></space>
    </>
  );
};
export default StudentsGrid;
