import classNames from 'classnames';
import React, {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useMaterialEffect} from '../../utils/hooks/useMaterialEffectHook';

export const Text = ({
  id,
  isName = false,
  accent = false,
  children = '',
  className = '',
  contenteditable = false,
  dataHeading,
  dataShrink,
  dataSize,
  dataIndex,
  dataColor,
  dataColumn,
  dataLine,
  dataText,
  dataWeight = '',
  dataWidth,
  dataHeadline,
  dataUppercase,
  dataVersioning,
  digit = false,
  error = false,
  ellipsis = false,
  highlight = false,
  large = false,
  light = false,
  medium = false,
  middle = false,
  nowrap = false,
  space = false,
  wrap = false,
  wrapLine = false,
  dataTransform = '',
  dataWord = '',
  ...rest
}) => {
  const {t} = useTranslation();
  const props = {};

  id && (props.id = id);
  accent && (props.accent = '');
  contenteditable && (props.contenteditable = contenteditable);
  dataHeading && (props['data-heading'] = '');
  dataSize && (props['data-size'] = dataSize);
  dataIndex && (props['data-index'] = dataIndex);
  dataColor && (props['data-color'] = dataColor);
  dataColumn && (props['data-column'] = dataColumn);
  dataLine && (props['data-line'] = dataLine);
  dataText && (props['data-text'] = dataText);
  dataShrink && (props['data-shrink'] = dataShrink);
  dataWeight && (props['data-weight'] = dataWeight);
  dataWidth && (props['data-width'] = dataWidth);
  dataTransform && (props['data-transform'] = dataTransform);
  dataHeadline && (props['data-headline'] = '');
  dataUppercase && (props['data-uppercase'] = '');
  dataVersioning && (props['data-versioning'] = dataVersioning);
  dataWord && (props['data-word'] = dataWord);
  digit && (props.digit = digit);
  error && (props.error = error);
  highlight && (props.highlight = highlight);
  large && (props.large = large);
  medium && (props.medium = medium);
  middle && (props.middle = middle);
  nowrap && (props.nowrap = nowrap);
  light && (props.light = '');
  ellipsis && (props.ellipsis = '');
  space && (props.space = '');
  wrap && (props.wrap = '');
  wrapLine && (props['wrap-line'] = wrapLine);

  return (
    <text className={classNames({}, className)} {...props} {...rest}>
      {isName || typeof children !== 'string' ? children : t(children)}
    </text>
  );
};

export const Button = React.forwardRef(
  (
    {
      accent = false,
      adaptive = false,
      className = '',
      disabled = false,
      error = false,
      extra = false,
      fab = false,
      fit = false,
      highlight = false,
      icon = '',
      iconMicro = false,
      large = false,
      xlarge = false,
      mini = false,
      micro = false,
      onClick,
      open = false,
      outline = false,
      preview = false,
      primary = false,
      rounded = false,
      show = false,
      space = false,
      special = false,
      text = '',
      wide = false,
      children,
      ...rest
    },
    ref
  ) => {
    const refObj = useRef();
    const [iconCode, fill] = icon.split('.');
    let iconProps = null;
    if (fill === 'fill') {
      iconProps = {fill: ''};
    }
    const {svg, onMouseDown, onMouseLeave, onMouseUp} = useMaterialEffect({
      ref: ref || refObj,
      isMaterial: true,
    });

    return (
      <div
        effect="material"
        ref={ref || refObj}
        className={classNames(
          'button',
          {
            accent,
            adaptive,
            error,
            extra,
            fab,
            fit,
            highlight,
            large,
            xlarge,
            mini,
            micro,
            open,
            outline,
            preview,
            primary,
            rounded,
            show,
            special,
            space,
            wide,
          },
          className
        )}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseLeave={onMouseLeave}
        onMouseUp={onMouseUp}
        {...rest}
        {...(disabled ? {disabled: true} : null)}
      >
        {iconCode && (
          <icon {...iconProps} {...(iconMicro ? {micro: ''} : null)}>
            {iconCode}
          </icon>
        )}
        {text && <Text>{text}</Text>}
        {children}
        {svg}
      </div>
    );
  }
);
