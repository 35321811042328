import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
//staging config
// const firebaseConfig = {
//   apiKey: 'AIzaSyA5vYjFAhswijxaWLLwzSs9ma0IsFwvr0g',
//   authDomain: 'ogma-knowledge-center-staging.firebaseapp.com',
//   databaseURL: 'https://ogma-knowledge-center-staging-default-rtdb.firebaseio.com',
//   projectId: 'ogma-knowledge-center-staging',
//   storageBucket: 'ogma-knowledge-center-staging.appspot.com',
//   messagingSenderId: '880134734205',
//   appId: '1:880134734205:web:bcfd37811f16889278cc14',
//   measurementId: 'G-QWXSWRHG1L',
// };

//production config
const firebaseConfig = {
  apiKey: 'AIzaSyA-bDivbUMtrX9mer5ReFnDw1enOZ-Sx9Q',
  authDomain: 'ogma-knowledge-center.firebaseapp.com',
  projectId: 'ogma-knowledge-center',
  storageBucket: 'ogma-knowledge-center.appspot.com',
  messagingSenderId: '367024325569',
  appId: '1:367024325569:web:2d720731ad1bd5ce44e540',
};

const app = initializeApp(firebaseConfig);

// const storage = getStorage();

// const getSomeImage = ref(storage, 'images/aa.jpg');

export const auth = getAuth(app);

export default app;
