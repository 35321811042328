import { Text } from "../../components/shared/index";

const Contact = () => {
  return (
    <view data-page="contact">
      <section data-versioning="light">
        <back data-background></back>
        <wrapper>
          <space></space>
          <Text
            dataSize="huge"
            dataTransform="lowercase"
            dataWeight="700"
            dataWord="break-word"
          >
            ContactPage.ContactTitle
          </Text>
          <space></space>
        </wrapper>
      </section>
      <section>
        <wrapper>
          <space></space>
          <group>
            <Text dataSize="large" dataWeight="700">
              ContactPage.Address
            </Text>
            <space width="small"></space>
            <space width="small"></space>
            <a
              target="_blank"
              href="https://www.google.com/maps/place/Ogma+Applications/40.2006541,44.472501,17z/data=!3m1!4b1!4m5!3m4!1s0x406abd7b28130f8b:0xfa58a298cabb46b5!8m2!3d40.20065!4d44.474695"
              underline="none"
              rel="noreferrer"
            >
              <Text dataSize="large" dataWeight="500" underline="">
                Address
              </Text>
            </a>
          </group>
          <space></space>
          <group>
            <Text dataSize="large" dataWeight="700">
              ContactPage.Email
            </Text>
            <space width="small"></space>
            <space width="small"></space>
            <a
              data-type="mail"
              href="mailto:info@ogmaknowledgecenter.com"
              underline="none"
            >
              <Text dataSize="large" dataWeight="500" underline="">
                info@ogmaknowledgecenter.com
              </Text>
            </a>
          </group>

          {/* <a href="tel:+37410350360">
            <Text dataSize="large" dataWeight="700">
              ContactPage.PhoneNumber
            </Text>
            <Text dataSize="large" dataWeight="700">
              + 374 10 350 360
            </Text>
          </a> */}
        </wrapper>
        <wrapper>
          <separator horizontal=""></separator>
        </wrapper>
        <wrapper>
          <Text dataSize="large">ContactPage.FollowText</Text>
          <space></space>
          <group data-left="small">
            <a
              href="https://www.facebook.com/OgmaKnowledgeCenter"
              rel="noreferrer"
              target="_blank"
              outline="none"
              effect="material"
              className="button accent rounded large"
              data-hover="white"
            >
              <icon>
                <svg width="24" height="24px" viewBox="0 0 24 24">
                  <path
                    fill="#000"
                    d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M18,5H15.5A3.5,3.5 0 0,0 12,8.5V11H10V14H12V21H15V14H18V11H15V9A1,1 0 0,1 16,8H18V5Z"
                  ></path>
                </svg>
              </icon>
              <space width="small"></space>
              <text>facebook</text>
            </a>
            <a
              href="https://www.linkedin.com/company/ogma-knowledge-center"
              rel="noreferrer"
              target="_blank"
              effect="material"
              outline="none"
              className="button accent rounded large"
              data-hover="white"
            >
              <icon>
                <svg width="24px" height="24px" viewBox="0 0 24 24">
                  <path
                    fill="#000"
                    d="M21,21H17V14.25C17,13.19 15.81,12.31 14.75,12.31C13.69,12.31 13,13.19 13,14.25V21H9V9H13V11C13.66,9.93 15.36,9.24 16.5,9.24C19,9.24 21,11.28 21,13.75V21M7,21H3V9H7V21M5,3A2,2 0 0,1 7,5A2,2 0 0,1 5,7A2,2 0 0,1 3,5A2,2 0 0,1 5,3Z"
                  ></path>
                </svg>
              </icon>
              <space width="small"></space>
              <text>Linkedin</text>
            </a>
          </group>
        </wrapper>
        <space></space>
      </section>
    </view>
  );
};

export default Contact;
