import { Text } from '../../components/shared/index';
import { ScrollPage } from '../../components/shared/scrollPage';
import { Link, useNavigate } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { useTranslation } from 'react-i18next';
import {
  CarouselItem,
  FeedbackItem,
  HomePageFeedbacksTemplate,
} from '../../components/shared/templates';
// import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { useEffect, useMemo, useState } from 'react';
import { getDatabase, onValue, ref } from 'firebase/database';

const Home = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [emulateTouch, setEmulateTouch] = useState(true);

  const [studentsData, setStudentsData] = useState({});

  useEffect(() => {
    const db = getDatabase();
    const starCountRef = ref(db, 'students');
    onValue(starCountRef, (snapshot) => {
      let result = snapshot.val();
      setStudentsData(result || []);
    });
  }, []);

  const feedbackKeys = useMemo(() => {
    if (!studentsData) {
      return [];
    }

    const keys = Object.keys(studentsData).filter(
      (key) => studentsData[key].feedback && studentsData[key].homepage_flag
    );

    keys.sort(
      (a, b) => studentsData[a].line_number - studentsData[b].line_number
    );

    return keys;
  }, [studentsData]);

  return (
    <>
      <Carousel
        emulateTouch
        showArrows={true}
        showStatus={false}
        className="data-fixed"
      >
        <ScrollPage dataSlide="1">
          <CarouselItem text="Home.Slide1" path={`/${i18n.language}/about`} />
        </ScrollPage>

        <ScrollPage dataSlide="2">
          <CarouselItem
            text="Home.Slide2"
            path={`/${i18n.language}/programs`}
          />
        </ScrollPage>

        <ScrollPage dataSlide="3">
          <CarouselItem
            text="Home.Slide3"
            path={`/${i18n.language}/community`}
            textLight
          />
        </ScrollPage>
      </Carousel>

      <section>
        <wrapper>
          <grid data-promo="">
            <item data-background="specific" data-color="specific">
              <Link
                to={`/${i18n.language}/about#principleSection1`}
                underline="none"
              >
                <Text dataHeadline dataSize="xx-large" dataUppercase>
                  Home.PrinciplesTitle1
                </Text>
                <space></space>
                <Text dataText="hide" dataSize="medium">
                  Home.PrinciplesDesc1
                </Text>

                <icon data-position="bottom" large="">
                  handshake
                </icon>
              </Link>
            </item>
            <item data-background="secondary" data-versioning="light">
              <Link
                to={`/${i18n.language}/about#principleSection2`}
                underline="none"
              >
                <Text dataHeadline dataSize="xx-large" dataUppercase>
                  Home.PrinciplesTitle2
                </Text>
                <space></space>
                <Text dataText="hide" dataSize="medium">
                  Home.PrinciplesDesc2
                </Text>
                <icon data-position="bottom" large="">
                  menu_book
                </icon>
              </Link>
            </item>
            <item data-background="primary" data-versioning="light">
              <Link
                to={`/${i18n.language}/about#principleSection3`}
                underline="none"
              >
                <Text dataHeadline dataSize="xx-large" dataUppercase>
                  Home.PrinciplesTitle3
                </Text>
                <space></space>
                <Text dataText="hide" dataSize="medium">
                  Home.PrinciplesDesc3
                </Text>
                <icon data-position="bottom" large="">
                  timeline
                </icon>
              </Link>
            </item>
            <item data-background="tertiary" data-versioning="light">
              <Link
                to={`/${i18n.language}/about#principleSection4`}
                underline="none"
              >
                <Text dataHeadline dataSize="xx-large" dataUppercase>
                  Home.PrinciplesTitle4
                </Text>
                <space></space>
                <Text dataText="hide" dataSize="medium">
                  Home.PrinciplesDesc4
                </Text>
                <icon data-text="hide" data-position="bottom" large="">
                  school
                </icon>
              </Link>
            </item>
          </grid>
        </wrapper>
      </section>
      <space></space>
      <section>
        <wrapper>
          <grid data-statistics>
            <item>
              <Text
                dataSize="xxx-large"
                dataUppercase=""
                dataWeight="700"
                dataColor="primary"
              >
                {new Date().getFullYear() - 2013}
              </Text>
              <space></space>
              <Text
                dataWidth="small"
                dataHeadline=""
                dataSize="xx-large"
                dataUppercase=""
              >
                Home.Statistics1
              </Text>
            </item>
            <item>
              <Text
                dataSize="xxx-large"
                dataUppercase=""
                dataWeight="700"
                dataColor="secondary"
              >
                400+
              </Text>
              <space></space>
              <Text
                dataWidth="small"
                dataHeadline=""
                dataSize="xx-large"
                dataUppercase=""
              >
                Home.Statistics2
              </Text>
            </item>
            <item>
              <Text
                dataSize="xxx-large"
                dataUppercase=""
                dataWeight="700"
                dataColor="tertiary"
              >
                14 - 55
              </Text>
              <space></space>
              <Text
                dataWidth="small"
                dataHeadline=""
                dataSize="xx-large"
                dataUppercase=""
              >
                Home.Statistics3
              </Text>
            </item>
          </grid>
        </wrapper>
        <wrapper data-align="center">
          <Link
            to={`/${i18n.language}/about#okcNumbers`}
            className="button large rounded accent"
            outline="none"
          >
            <Text>LearnMore</Text>
            <icon>arrow_right_alt</icon>
          </Link>
        </wrapper>
      </section>
      <space></space>
      <section data-fixed="medium">
        <back style={{ background: 'rgb(132 147 202)' }}>
          <thumb data-thumb="temp">
            <img src="../content/img/res_13.jpg" alt="" />
          </thumb>
        </back>
        <space></space>
        <wrapper data-versioning="light" data-justify="center">
          <Text dataSize="x-large" dataWeight="700" dataWord="break-word">
            ApplyPage.Apply
          </Text>
          <space></space>
          <Text dataSize="large" dataWidth="large" data-line="medium">
            Home.ApplyNowDesc
          </Text>
          <space></space>
          <space></space>
          <Link
            to={`/${i18n.language}/apply`}
            underline=""
            data-cursor="pointer"
            className="button large  accent"
            outline="none"
          >
            <Text>ApplyNow</Text>
          </Link>
          <space></space>
        </wrapper>
      </section>
      <space></space>
      <wrapper>
        <group data-wrap="no" data-align="center">
          <Text
            dataSize="x-large"
            dataWeight="700"
            dataColor="accent"
            dataWord="break-word"
          >
            Home.StudentsFeedback
          </Text>
          <group data-position="right" data-width="auto">
            <Link
              to={`/${i18n.language}/community#feedbacks`}
              className="button large rounded accent"
              outline="none"
            >
              <Text>Home.SeeMoreFeedbacks</Text>
              <icon>arrow_right_alt</icon>
            </Link>
          </group>
        </group>
      </wrapper>

      {feedbackKeys?.length > 0 && (
        <Carousel
          emulateTouch={emulateTouch}
          showArrows={true}
          showStatus={false}
          className="data-feedback"
          showThumbs={false}
        >
          {feedbackKeys.map((key, i) => {
            const item = studentsData[key];
            item.key = key;
            return (
              <ScrollPage noBackground key={key}>
                <FeedbackItem
                  data={item}
                  key={key}
                  isCarouselItem
                  popupOpenedCallback={(isOpen) => {
                    setEmulateTouch(!isOpen);
                  }}
                />
              </ScrollPage>
            );
          })}
        </Carousel>
      )}
      <space medium=""></space>
    </>
  );
};

export default Home;
