import { getDatabase, ref, remove } from 'firebase/database';
import { deleteObject, getStorage, ref as sRef } from 'firebase/storage';

const ModalWindow = ({ data, onClose }) => {
  const handleDeleteButton = () => {
    if (data.image_file && data.image_url) {
      const storage = getStorage();
      const deleteRef = sRef(storage, data.image_file);
      deleteObject(deleteRef)
        .then(() => {
          console.log('File deleted successfully!');
        })
        .catch((error) => {
          console.log(error);
          console.error('Uh-oh, an error occurred!');
        });
    }
    const db = getDatabase();

    const tasksRef = ref(db, `students/${data?.key}`);

    remove(tasksRef).then(() => {
      onClose?.();

      console.log('item removed');
    });
  };

  return (
    <>
      <view data-space="30">
        <text data-size="medium">Are you sure you want to delete this student?</text>
      </view>
      <toolbar data-space="20">
        <group data-gap="20">
          <div className="button primary large" onClick={handleDeleteButton}>
            Yes, Delete
          </div>
          <div className="button large" onClick={onClose}>
            Cancel
          </div>
        </group>
      </toolbar>
    </>
  );
};

export default ModalWindow;
