import { Text } from "../../components/shared/index";

const Programs = () => {
  return (
    <view data-page="program">
      <section data-versioning="light">
        <back data-background></back>
        <wrapper>
          <space></space>
          <Text
            dataSize="huge"
            dataTransform="lowercase"
            dataWeight="700"
            dataWord="break-word"
          >
            Programs.EducationalProgram
          </Text>
          <space></space>
        </wrapper>
      </section>
      <space></space>
      <section>
        
        <wrapper>
          <Text dataSize="large" dataWeight="700">
            Programs.Desc
          </Text>
          <space></space>
          <space></space>
          <space data-display="desk"></space>
          <grid data-stage>
            <item>
            <Text dataSize="large" dataColor="primary"  dataWidth="middle" dataWeight="700">
                Programs.Phase_1
              </Text>
              <space></space>
              <Text dataSize="large">
                Programs.Phase_1_Duration
              </Text>
            </item>
            <item>
              <Text dataSize="large" dataColor="secondary" dataWidth="middle" dataWeight="700">
                Programs.Phase_2
              </Text>
              <space></space>
              <Text dataSize="large">
                Programs.Phase_2_Duration
              </Text>
            </item>
            <item>
              <Text dataSize="large" dataColor="tertiary" dataWidth="middle" dataWeight="700">
                Programs.Phase_3
              </Text>
              <space></space>
              <Text dataSize="large">
                Programs.Phase_3_Duration
              </Text>
            </item>
          </grid>
        </wrapper>
      </section>
      <space></space>
      <section>
        <wrapper>
          <Text dataSize="medium">
            Programs.ProgramInfo_1
          </Text>
          <space small=""></space>
          <space tiny=""></space>
          <Text dataSize="medium">
            Programs.ProgramInfo_2
          </Text>
          <space small=""></space>
          <space tiny=""></space>
          <Text dataSize="medium">
            Programs.ProgramInfo_3
          </Text>
        </wrapper>
      </section>
      <space></space>
      {/* <section>
        <wrapper>
          <Text
            dataSize="middle"
            dataWeight="700"
            dataColor="accent"
            dataWord="break-word"
          >
            Archive
          </Text>
          <space></space>
          <space temp=""></space>
        </wrapper>
      </section> */}
    </view>
  );
};

export default Programs;
