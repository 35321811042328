import React, { useState, useEffect, useCallback, useRef } from 'react';
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import {
  getDatabase,
  ref,
  child,
  push,
  update,
  onValue,
} from 'firebase/database';
import { auth } from '../../firebase';
import { authErrors } from '../../utils/defines';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { setUserData } from '../../store/authSlice';
import i18n from '../../i18n';
import { defaultLang } from '..';
import { useTranslation } from 'react-i18next';
import { addMessage } from '../../store/componentsSlice';
import { Button } from '../../components/shared';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleKeyPress = (event) => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
      onLogin(event);
    }
  };

  const onLogin = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const {
          accessToken,
          displayName,
          email,
          emailVerified,
          isAnonymous,
          providerId,
          uid,
          tenantId,
        } = userCredential.user;
        dispatch(
          setUserData({
            accessToken,
            displayName,
            email,
            emailVerified,
            isAnonymous,
            providerId,
            uid,
            tenantId,
          })
        );
        dispatch(
          addMessage({
            type: 'success',
            message: t('Message.SuccessfullyLoggedIn', {
              email: userCredential.user.email,
            }),
          })
        );
        navigate(`${i18n.language || defaultLang}/home`, { replace: true });
      })
      .catch((error) => {
        dispatch(
          addMessage({ type: 'error', message: authErrors[error.code] })
        );
      });
  };

  return (
    <view>
      <section>
        <wrapper data-align="center">
          <form data-type="login">

            <text data-size="x-large" data-weight="700" data-space="10">
              Sign In
            </text>   
            <div className="field">
              <div className="form_fields">
                <div className="data_label"></div>
                <div className="field_cont">
                  <icon>mail</icon>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    placeholder="Email"
                    required
                    onKeyDown={handleKeyPress}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <i></i>
                </div>
              </div>
            </div>
            <div className="field">
              <div className="form_fields">
                <div className="data_label"></div>
                <div className="field_cont">
                  <icon>lock</icon>
                  <input
                    id="password"
                    name="password"
                    type={passwordVisible ? 'text' : 'password'}
                    placeholder="Password"
                    required
                    onKeyDown={handleKeyPress}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <Button
                    onClick={() => {
                      setPasswordVisible(!passwordVisible);
                    }}
                    text={passwordVisible ? 'Hide' : 'Show'}
                  />
                  <i></i>
                </div>
              </div>
            </div>
            <space mini=""></space>
            <Button
              onClick={onLogin}
              type="button"
              primary
              large
              text="Sign In"
            />
          </form>
        </wrapper>
      </section>
    </view>
  );
};

export default Login;
