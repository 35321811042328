import { Upload } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addMessage } from '../../store/componentsSlice';
import { uploadTypesErrorMessage } from '../../utils/defines';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';

const AvatarUpload = React.forwardRef(({ onChange, label, value, setImage, ...rest }, ref) => {
  const imageRef = useRef({ imageSet: false, localImage: null, genderDirty: false });
  const dispatch = useDispatch();
  const { watch, setValue, formState } = useFormContext();
  const watchGender = watch('gender');
  const imageName = watch('image_file');
  const [uploadedAvatarInfo, setUploadedAvatarInfo] = useState();
  const [selectedImage, setSelectedImage] = useState();

  useEffect(() => {
    if (!imageRef?.current?.imageSet) {
      if (value || imageName) {
        if (value) {
          setUploadedAvatarInfo({ url: value, name: imageName });
        }
        setSelectedImage(value ? 'custom' : imageName);
        imageRef.current.imageSet = true;
      }
    }
  }, [value, imageName]);

  const avatarImages = useMemo(() => {
    if (!watchGender) {
      return [];
    }
    const arr = [];
    for (let i = 1; i < 11; i++) {
      arr.push(`${watchGender}_${i}.jpg`);
    }
    return arr;
  }, [watchGender]);

  useEffect(() => {
    if (watchGender && (formState.dirtyFields.gender || imageRef.current.genderDirty)) {
      imageRef.current.genderDirty = true;
      setSelectedImage('custom');

      setValue('templateImage', '');

      if (selectedImage !== 'custom') {
        if (!imageRef.current.localImage) {
          if (uploadedAvatarInfo?.url) {
            setValue('image_url', uploadedAvatarInfo?.url);
            setValue('image_file', uploadedAvatarInfo?.name);
          } else {
            setValue('image_file', '');
          }
        }
      }
    }
  }, [watchGender, setValue, formState.dirtyFields, uploadedAvatarInfo]);

  useEffect(() => {
    return () => {
      onChange?.('');
    };
  }, [onChange]);

  const props = {
    onRemove: () => {
      onChange?.('');
      setImage(null);
    },
    beforeUpload: (fl) => {
      let allowedTypes = ['image/jpeg', 'image/png'];
      let allowUpload = allowedTypes.indexOf(fl.type) > -1;

      if (!allowUpload) {
        dispatch(addMessage({ type: 'error', message: uploadTypesErrorMessage }));
        return false;
      }

      const isLtM = fl.size / 1024 / 1024 < 1;
      if (!isLtM) {
        dispatch(addMessage({ type: 'error', message: 'Image must be smaller than 1MB!' }));
        return false;
      }

      setImage(fl);
      imageRef.current.localImage = fl;
      setSelectedImage('custom');
      setValue('templateImage', '');

      const reader = new FileReader();
      reader.addEventListener(
        'load',
        function () {
          onChange?.(reader.result);
        },
        false
      );
      reader.readAsDataURL(fl);
      return false;
    },
  };

  const uploadBtn = (
    <Upload showUploadList={false} ref={ref} {...props} {...rest} multiple={false}>
      <div className="button micro primary rounded" data-position="absolute">
        <icon micro="">upload</icon>
      </div>
    </Upload>
  );

  const singleImgExists = !!value;

  const content = (
    <>
      <group data-type="avatar">
        <div className="avatar_cont">
          <div
            className={classNames('avatar', { selected: selectedImage === 'custom' })}
            onClick={() => {
              setSelectedImage('custom');
              setValue('templateImage', '');
            }}
          >
            {singleImgExists ? (
              <img src={value || `../content/img/${imageName}`} alt="avatar" />
            ) : (
              // <img src={value || `../content/img/${imageName}`} alt="avatar" />
              <icon light="" medium="">
                photo
              </icon>
            )}
          </div>
          {uploadBtn}
        </div>
        <grid data-scroll="horizontal" data-space="3">
          <wrap data-gap="7" data-group="avatar">
            {!!avatarImages?.length &&
              avatarImages.map((img) => {
                return (
                  <thumb
                    key={img}
                    className={classNames({ selected: selectedImage === img })}
                    onClick={() => {
                      setSelectedImage(img);
                      setValue('templateImage', img);
                    }}
                    data-ratio="1:1"
                    rounded=""
                    data-cursor="pointer"
                  >
                    <picture>
                      <img src={`../content/img/${img}`} alt="" />
                    </picture>
                  </thumb>
                );
              })}
          </wrap>
        </grid>
        {/*<group data-align="center" data-justify="center" data-space="20" data-gap="small" data-width="auto">
         {uploadBtn} 
          {singleImgExists && (
            <>
              <separator vertical=""></separator>
              <Button
                text="Delete"
                large
                highlight
                onClick={(e) => {
                  onChange?.('');
                  e.stopPropagation();
                }}
              />
            </>
          )}
        </group>*/}
      </group>
    </>
  );

  return content;
});

export default AvatarUpload;
