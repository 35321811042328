import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';

const LanguageSwitch = () => {
  const [language, setLanguage] = useState(localStorage.getItem('i18nextLng'));
  const location = useLocation();
  const navigate = useNavigate();
  const {i18n} = useTranslation();

  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);

  const handleClick = useCallback(() => {
    const newLang = language === 'en' ? 'hy' : 'en';

    setLanguage(newLang);
    let path = location.pathname.slice(location.pathname.lastIndexOf('/'));
    navigate(`/${newLang}` + path + location.search + location.hash);
  }, [language, navigate, location]);

  return (
    <div className="button mini rounded" onClick={handleClick}>
      <text>{language === 'en' ? 'HY' : 'EN'}</text>
    </div>
  );
};

export default LanguageSwitch;
