import { Text } from '../../components/shared/index';
import { useHashFragment } from '../../utils/hooks/useHashFragment';
import StudentsGrid from './students';
import FeedbacksGrid from './feedbacks';

const Community = () => {
  return (
    <>
      <view data-page="community">
        <section data-versioning="light">
          <back data-background></back>
          <wrapper>
            <space></space>
            <Text dataSize="huge" dataTransform="lowercase" dataWeight="700" dataWord="break-word">
              Community.Intro
            </Text>
            <space></space>
          </wrapper>
        </section>
        <space></space>
        <section id="founders">
          <wrapper>
            <Text data-size="x-large" dataWeight="700" dataColor="accent" dataWord="break-word">
              Community.FoundersTitle
            </Text>
            <space></space>
            <grid data-type="general">
              <item>
                <group data-direction="column">
                  <thumb data-thumb="standard" rounded="">
                    <picture>
                      <img src="../content/img/HarmikB.jpg" alt="" />
                    </picture>
                  </thumb>
                  <space></space>
                  <Text data-weight="700" data-size="medium">
                    Community.Founders.Harmik.Name
                  </Text>
                  <space tiny=""></space>
                  <Text>Community.Founders.Harmik.Position</Text>
                  <space mini=""></space>
                  <Text dataSize="small">Community.Founders.Harmik.Desc</Text>
                </group>
              </item>
              <item>
                <group data-direction="column">
                  <thumb data-thumb="standard" rounded="">
                    <picture>
                      <img src="../content/img/KarenAzatyan.jpg" alt="" />
                    </picture>
                  </thumb>
                  <space></space>
                  <Text data-weight="700" data-size="medium">
                    Community.Founders.CEO.Name
                  </Text>
                  <space tiny=""></space>
                  <Text>Community.Founders.CEO.Position</Text>
                  <space mini=""></space>
                  <Text dataSize="small">Community.Founders.CEO.Desc</Text>
                </group>
              </item>
              <item>
                <group data-direction="column">
                  <thumb data-thumb="standard" rounded="">
                    <picture>
                      <img src="../content/img/NikitaMelik-Haykazyan.jpg" alt="" />
                    </picture>
                  </thumb>
                  <space></space>
                  <Text data-weight="700" data-size="medium">
                    Community.Founders.Nikit.Name
                  </Text>
                  <space tiny=""></space>
                  <Text>Community.Founders.Nikit.Position</Text>
                  <space mini=""></space>
                  <Text dataSize="small">Community.Founders.Nikit.Desc</Text>
                </group>
              </item>
            </grid>
          </wrapper>
        </section>
        <space></space>
        <space></space>
        <section id="teachers" data-type="teacher">
          <back></back>
          <wrapper>
            <space></space>
            <Text data-size="x-large" dataWeight="700" dataColor="accent" dataWord="break-word">
              Community.Teachers.Desc
            </Text>

            <space></space>
            <grid data-type="general">
              <item>
                <group data-direction="column">
                  <thumb data-thumb="standard" rounded="">
                    <picture>
                      <img src="../content/img/NikitaMelik-Haykazyan.jpg" alt="" />
                    </picture>
                  </thumb>
                  <space></space>
                  <Text data-weight="700" data-size="medium">
                    Community.Teachers.Nikit.Name
                  </Text>
                  <space mini=""></space>
                  <Text data-size="small">Community.Teachers.Nikit.Part_1</Text>
                  <space tiny=""></space>
                  <Text data-size="small">Community.Teachers.Nikit.Part_2</Text>
                  <space small=""></space>
                  <Text data-size="small">Community.Teachers.Nikit.Part_3</Text>
                  <space small=""></space>
                  <Text data-size="small">Community.Teachers.Nikit.Part_4</Text>
                </group>
              </item>
              <item>
                <group data-direction="column">
                  <thumb data-thumb="standard" rounded="">
                    <picture>
                      <img src="../content/img/SamvelHakobyan.jpeg" alt="" />
                    </picture>
                  </thumb>
                  <space></space>
                  <Text data-weight="700" data-size="medium">
                    Community.Teachers.Samvel.Name
                  </Text>
                  <space mini=""></space>
                  <Text data-size="small">Community.Teachers.Samvel.Part_1</Text>
                  <space tiny=""></space>
                  <Text data-size="small">Community.Teachers.Samvel.Part_2</Text>
                </group>
              </item>
            </grid>
            <space></space>
            <space></space>
          </wrapper>
        </section>
        <space></space>
        <section>
          <wrapper>
            <StudentsGrid />
            <FeedbacksGrid />
          </wrapper>
        </section>
      </view>
    </>
  );
};

export default Community;
