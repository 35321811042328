import { createSlice } from '@reduxjs/toolkit';
import { popupRoot } from '../components/shared/popup';

const initialState = {
  popupsCount: 0,
  messages: [],
};

export const componentsSlice = createSlice({
  name: 'components',
  initialState,
  reducers: {
    addMessage(state, payload) {
      state.messages = [...state.messages, payload.payload];
    },
    clearMessages(state) {
      state.messages = [];
    },
    addPopup(state) {
      if (state.popupsCount === 0) {
        popupRoot.classList.add('open');
      }
      state.popupsCount++;
    },
    removePopup(state) {
      state.popupsCount--;
      if (state.popupsCount === 0) {
        popupRoot.classList?.remove('open');
      }
    },
  },
});

export const { addMessage, clearMessages, addPopup, removePopup } = componentsSlice.actions;
export default componentsSlice.reducer;
