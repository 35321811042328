import {useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';

export default function ScrollToTop() {
  const {pathname} = useLocation();
  const ref = useRef({pathname});

  useEffect(() => {
    if (pathname.slice(3) !== ref.current.pathname.slice(3)) {
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant',
      });
    }
    ref.current.pathname = pathname;
  }, [pathname]);

  return null;
}
