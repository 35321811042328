import {Link, useLocation} from 'react-router-dom';
import NavBarTitles from '../../utils/defines';
import {Text} from '../../components/shared/index';
import {useTranslation} from 'react-i18next';

const Navigation = (props) => {
  const {pathname} = useLocation();
  const {i18n} = useTranslation();

  const scrollHandler = () => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  };

  const handleClick = () => {
    if (props.handleMenuClick) {
      props.handleMenuClick();
    }

    document.documentElement.classList.remove('open_nav');
    document.getElementById('menu').classList.remove('open');
  };

  return (
    <ul>
      <li
        {...(pathname.toLowerCase() === `/${i18n.language}/home`
          ? {className: 'selected'}
          : null)}
      >
        <Link
          to={`/${i18n.language}/home`}
          onClick={() => {
            handleClick();
            pathname.toLowerCase() === `/${i18n.language}/home` &&
              scrollHandler();
          }}
        >
          <Text>{NavBarTitles.GENERAL}</Text>
        </Link>
      </li>
      <li
        {...(pathname.toLowerCase() === `/${i18n.language}/about`
          ? {className: 'selected'}
          : null)}
      >
        <Link
          to={`/${i18n.language}/about`}
          onClick={() => {
            handleClick();
            pathname.toLowerCase() === `/${i18n.language}/about` &&
              scrollHandler();
          }}
        >
          <Text>{NavBarTitles.ABOUT}</Text>
        </Link>
        {/* linkern imanal social */}
      </li>
      <li
        {...(pathname.toLowerCase() === `/${i18n.language}/programs`
          ? {className: 'selected'}
          : null)}
      >
        <Link
          to={`/${i18n.language}/programs`}
          onClick={() => {
            handleClick();
            pathname.toLowerCase() === `/${i18n.language}/programs` &&
              scrollHandler();
          }}
        >
          <Text>{NavBarTitles.PROGRAMS}</Text>
        </Link>
      </li>
      <li
        {...(pathname.toLowerCase() === `/${i18n.language}/community`
          ? {className: 'selected'}
          : null)}
      >
        <Link
          to={`/${i18n.language}/community`}
          onClick={() => {
            handleClick();
            pathname.toLowerCase() === `/${i18n.language}/community` &&
              scrollHandler();
          }}
        >
          <Text>{NavBarTitles.COMMUNITY}</Text>
        </Link>
      </li>
      <li
        {...(pathname.toLowerCase() === `/${i18n.language}/apply`
          ? {className: 'selected'}
          : null)}
      >
        <Link
          to={`/${i18n.language}/apply`}
          onClick={() => {
            handleClick();
            pathname.toLowerCase() === `/${i18n.language}/apply` &&
              scrollHandler();
          }}
        >
          <Text>{NavBarTitles.APPLY}</Text>
        </Link>
      </li>
      <li
        {...(pathname.toLowerCase() === `/${i18n.language}/contacts`
          ? {className: 'selected'}
          : null)}
      >
        <Link
          to={`/${i18n.language}/contacts`}
          onClick={() => {
            handleClick();
            pathname.toLowerCase() === `/${i18n.language}/contacts` &&
              scrollHandler();
          }}
        >
          <Text>{NavBarTitles.CONTACT_US}</Text>
        </Link>
      </li>
    </ul>
  );
};

export default Navigation;
