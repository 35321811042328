import { useSelector } from 'react-redux';
import { Text } from '../shared/index';
import { useTranslation } from 'react-i18next';
import Popup from './popup';
import PopupForm from '../../pages/community/popupForm';
import { useMemo, useState } from 'react';
import ModalWindow from './modalWindow';
import { ScrollPage } from './scrollPage';
import { Carousel } from 'antd';
import { randomIntFromInterval } from '../../utils/defines';
import { useNavigate } from 'react-router-dom';

export const CarouselItem = ({ text, textLight, path }) => {
  const navigate = useNavigate();
  return (
    <>
      <space data-display="desk"></space>
      <Text
        dataSize="x-large"
        dataWeight="700"
        dataWidth="large"
        {...(textLight ? { dataVersioning: 'light' } : { dataColor: 'specific' })}
      >
        {text}
      </Text>
      <space medium=""></space>
      <div
        className="button primary large"
        onClick={() => {
          navigate(path);
        }}
      >
        <Text>LearnMore</Text>
      </div>
      <space large=""></space>
    </>
  );
};

export const StudentsProfileItem = ({ data }) => {
  const isLoggedIn = !!useSelector((s) => s.auth.uid);
  const { i18n } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  return (
    <>
      {isOpen && (
        <Popup
          title="Edit Student"
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <PopupForm
            data={data}
            onClose={() => {
              setIsOpen(false);
            }}
          />
        </Popup>
      )}
      {isDelete && (
        <Popup
          title="Delete"
          onClose={() => {
            setIsDelete(false);
          }}
        >
          <ModalWindow
            data={data}
            onClose={() => {
              setIsDelete(false);
            }}
          />
        </Popup>
      )}
      <item>
        <group data-direction="column" data-position="relative">
          {isLoggedIn && (
            <group data-element="transform" data-position="absolute" data-justify="end">
              <div
                className="button mini"
                rounded=""
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                <icon>edit</icon>
              </div>
              <div
                onClick={() => {
                  setIsDelete(true);
                }}
                className="button mini"
                rounded=""
              >
                <icon>delete</icon>
              </div>
            </group>
          )}
          <thumb data-thumb="standard" rounded="">
            <picture>
              <img src={data.image_url || `../content/img/${data.image_file}`} alt="" />
            </picture>
          </thumb>
          <space></space>
          <Text isName data-weight="700" data-size="medium">
            {data?.student_name[i18n.language]}
          </Text>
          {data?.position && (
            <>
              <space tiny=""></space>
              <span>
                <Text isName>{data?.position}</Text>{' '}
                {!!data?.work_place && (
                  <>
                    | <Text isName>{data?.work_place}</Text>
                  </>
                )}
              </span>
            </>
          )}
        </group>
      </item>
    </>
  );
};

export const FeedbackItem = ({ data, isCarouselItem, popupOpenedCallback, isLastItem }) => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const isLoggedIn = !!useSelector((s) => s.auth.uid);

  return (
    <>
      {isOpen && (
        <Popup
          title="Edit"
          onClose={() => {
            popupOpenedCallback?.(false);
            setIsOpen(false);
          }}
        >
          <PopupForm
            data={data}
            onClose={() => {
              popupOpenedCallback?.(false);
              setIsOpen(false);
            }}
          />
        </Popup>
      )}
      <group data-direction="column" data-type="general" data-position="relative">
        {isLoggedIn && (
          <group data-element="transform" data-position="absolute" data-justify="end">
            <div
              className="button mini"
              rounded=""
              onClick={() => {
                popupOpenedCallback?.(true);
                setIsOpen(true);
              }}
            >
              <icon>edit</icon>
            </div>
          </group>
        )}
        <group data-wrap="adaptive">
          <media>
            <thumb data-thumb="small" rounded="">
              <picture>
                <img src={data.image_url || `../content/img/${data.image_file}`} alt="" />
              </picture>
            </thumb>
          </media>
          <space width="mini"></space>

          <group data-direction="column">
            <Text isName dataWeight="700" dataSize="medium">
              {data.student_name[i18n.language]}
            </Text>
            <span>
              <Text isName>{data?.position}</Text>{' '}
              {!!data?.work_place && (
                <>
                  | <Text isName>{data?.work_place}</Text>
                </>
              )}
            </span>
          </group>
        </group>
        <space width="mini"></space>
        <Text isName dataSize="small" dataLine="pre-line" {...(isCarouselItem ? { 'data-clamp': '5' } : null)}>
          {data.feedback}
        </Text>
      </group>
      <space></space>
      {!isCarouselItem && !isLastItem && <separator horizontal=""></separator>}
      <space></space>
    </>
  );
};
