import { DatePicker, Radio, Upload } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Input, { TextArea } from '../../components/shared/inputs';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { getDatabase, ref, child, push, update, onValue, set } from 'firebase/database';

import { deleteObject, getDownloadURL, getStorage, ref as sRef, uploadBytes } from 'firebase/storage';
import { useEffect, useState } from 'react';
import AvatarUpload from '../../components/shared/AvatarUpload';
import { useDispatch } from 'react-redux';
import { addMessage } from '../../store/componentsSlice';
import {
  imageErrorMessage,
  nameErrorMessage,
  simpleErrorMessage,
  studentUpdatedSuccessMessage,
} from '../../utils/defines';
import Loader from '../../components/shared/loader';
import { v4 as uuidv4 } from 'uuid';

dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';

const defaultValues = {
  student_name: {
    en: '',
    hy: '',
  },
  gender: 'male',
  work_place: '',
  position: '',
  feedback: '',
  image_url: '',
  image_file: '',
  line_number: '0',
  homepage_flag: false,
};

const PopupForm = ({ data, onClose }) => {
  const dataCopy = data ? { ...data } : null;
  const [image, setImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const storage = getStorage();
  const db = getDatabase();

  const formMethods = useForm({ defaultValues });
  const { control, getValues, reset, setValue } = formMethods;

  const disabledDate = (current) => {
    return current && current > dayjs()?.startOf('day');
  };

  useEffect(() => {
    if (dataCopy) {
      dataCopy.date_of_birth = dataCopy.date_of_birth ? dayjs(dataCopy?.date_of_birth, dateFormat) : '';
      dataCopy.start_end_date = [];
      dataCopy.start_end_date.push(dataCopy.start_date ? dayjs(dataCopy?.start_date, dateFormat) : '');
      dataCopy.start_end_date.push(dataCopy.end_date ? dayjs(dataCopy?.end_date, dateFormat) : '');

      delete dataCopy.start_date;
      delete dataCopy.end_date;

      if (dataCopy.image_file?.includes('male')) {
        dataCopy.templateImage = dataCopy.image_file;
      }

      reset(dataCopy);
    }
  }, []);

  const saveImage = (image) => {
    const imgName = `images/${image.uid || uuidv4()}`;
    const storageRef = sRef(storage, imgName);

    setIsLoading(true);
    uploadBytes(storageRef, image, {
      contentType: 'image/jpeg',
    })
      .then(() => {
        getDownloadURL(storageRef)
          .then((downloadUrl) => {
            setValue('image_url', downloadUrl);
            setValue('image_file', imgName);

            saveFormData();
          })
          .catch((error) => {
            setIsLoading(false);
            console.error(error);
          });
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };

  const handleSubmit = () => {
    const formData = getValues();
    if (!formData.student_name?.en?.trim() || !formData.student_name?.hy?.trim()) {
      dispatch(
        addMessage({
          type: 'error',
          message: nameErrorMessage,
        })
      );
      return;
    }

    if (image) {
      saveImage(image);
    } else {
      saveFormData();
    }
  };

  const saveFormData = () => {
    const formData = getValues();
    formData.date_of_birth = formData?.date_of_birth ? formData?.date_of_birth?.format('DD/MM/YYYY') : '';

    if (formData?.start_end_date) {
      formData.start_date = formData?.start_end_date[0] ? formData?.start_end_date[0]?.format('DD/MM/YYYY') : '';
      formData.end_date = formData?.start_end_date[1] ? formData?.start_end_date[1]?.format('DD/MM/YYYY') : '';
    }
    delete formData?.start_end_date;

    formData.student_name.en = formData.student_name.en?.trim();
    formData.student_name.hy = formData.student_name.hy?.trim();
    formData.work_place = formData.work_place?.trim();
    formData.position = formData.position?.trim();
    formData.feedback = formData.feedback?.trim();

    // if (!formData.templateImage && dataCopy.image_file?.includes('male')) {
    //   formData.templateImage = dataCopy.image_file;
    // }

    if (formData.templateImage) {
      formData.image_url = '';
      formData.image_file = formData.templateImage;
      delete formData.templateImage;
    } else {
      if (!image && !formData.image_url) {
        dispatch(
          addMessage({
            type: 'error',
            message: imageErrorMessage,
          })
        );
        return;
      }
    }

    if (dataCopy) {
      set(ref(db, 'students/' + dataCopy.key), formData)
        .then(() => {
          const needDeleteOldImage =
            (dataCopy.image_url && (image || !formData.image_url)) ||
            (dataCopy.image_file &&
              dataCopy.image_file !== formData.image_file &&
              !dataCopy.image_file?.includes('male'));
          if (needDeleteOldImage) {
            const deleteRef = sRef(storage, dataCopy.image_file);
            deleteObject(deleteRef)
              .then(() => {
                onClose?.();
                console.log('File deleted successfully!');
              })
              .catch((error) => {
                console.log(error);
              });
          }
          setIsLoading(false);
          dispatch(
            addMessage({
              type: 'success',
              message: studentUpdatedSuccessMessage,
            })
          );
          !needDeleteOldImage && onClose?.();
        })
        .catch((error) => {
          setIsLoading(false);
          dispatch(
            addMessage({
              type: 'error',
              message: simpleErrorMessage,
            })
          );
        });
    } else {
      const newPostKey = push(child(ref(db), 'students'))?.key;
      const updates = {};
      updates['/students/' + newPostKey] = formData;

      update(ref(db), updates)
        .then(() => {
          dispatch(
            addMessage({
              type: 'success',
              message: studentUpdatedSuccessMessage,
            })
          );
          setIsLoading(false);
          onClose?.();
        })
        .catch(() => {
          setIsLoading(false);
          dispatch(
            addMessage({
              type: 'error',
              message: simpleErrorMessage,
            })
          );
        });
    }
  };

  return (
    <FormProvider {...formMethods}>
      <view data-type="fit">
        <view data-type="fit" data-scroll data-space="other">
          {isLoading && <Loader />}
          <group data-gap="15" data-type="student_table">
            <Controller
              control={control}
              name="student_name.en"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
              }) => {
                return (
                  <Input
                    type="text"
                    required
                    invalid={invalid}
                    error={error}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value || ''}
                    title="Student Name in English"
                    placeholder="Name in English"
                    ref={ref}
                  />
                );
              }}
            />

            <Controller
              control={control}
              name="student_name.hy"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
              }) => (
                <Input
                  type="text"
                  required
                  invalid={invalid}
                  error={error}
                  onBlur={onBlur}
                  title="Student Name in Armenian"
                  placeholder="Name in Armenian"
                  onChange={onChange}
                  value={value || ''}
                  ref={ref}
                />
              )}
            />

            <Controller
              control={control}
              name="date_of_birth"
              format={dateFormat}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <div className="field third_part">
                  <div className="form_fields">
                    <div className="data_label">Date of Birth</div>
                    <div className="field_cont">
                      <DatePicker
                        format={dateFormat}
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value || ''}
                        disabledDate={disabledDate}
                        placeholder="Select a Date"
                        ref={ref}
                        size="large"
                      />
                    </div>
                  </div>
                  <space small=""></space>
                </div>
              )}
            />

            <Controller
              control={control}
              name="gender"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <Radio.Group value={value} onChange={onChange} ref={ref} size="large">
                  <Radio.Button value="male">Male</Radio.Button>
                  <Radio.Button value="female">Female</Radio.Button>
                </Radio.Group>
              )}
            />
            <Controller
              control={control}
              name="image_url"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
              }) => {
                return (
                  <AvatarUpload
                    onChange={onChange}
                    setImage={setImage}
                    onBlur={onBlur}
                    value={value}
                    name={name}
                    ref={ref}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="position"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
              }) => (
                <Input
                  type="text"
                  invalid={invalid}
                  error={error}
                  onBlur={onBlur}
                  title="Student Position"
                  placeholder="Position"
                  onChange={onChange}
                  value={value || ''}
                  ref={ref}
                />
              )}
            />

            <Controller
              control={control}
              name="work_place"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
              }) => (
                <Input
                  type="text"
                  invalid={invalid}
                  error={error}
                  onBlur={onBlur}
                  title="Student Work Place"
                  placeholder="Work Place"
                  onChange={onChange}
                  value={value || ''}
                  ref={ref}
                />
              )}
            />

            <Controller
              control={control}
              name="start_end_date"
              format={dateFormat}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <div className="field third_part">
                  <div className="form_fields">
                    <div className="data_label">Education Date</div>
                    <div className="field_cont">
                      <RangePicker
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value || ''}
                        ref={ref}
                        format={dateFormat}
                        placeholder={['From', 'To']}
                        size="large"
                      />
                    </div>
                  </div>
                </div>
              )}
            />

            <Controller
              control={control}
              name="feedback"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <TextArea
                  title="Feedback"
                  onBlur={onBlur}
                  placeholder="Write a Feedback..."
                  onChange={onChange}
                  value={value || ''}
                  ref={ref}
                />
              )}
            />

            <Controller
              control={control}
              name="homepage_flag"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
              }) => (
                <label classic="">
                  <input type="checkbox" onBlur={onBlur} onChange={onChange} checked={!!value} ref={ref} />
                  <box>
                    <check></check>
                    <ink></ink>
                  </box>
                  <text>Show on Homepage</text>
                </label>
              )}
            />

            <Controller
              control={control}
              name="line_number"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
              }) => (
                <Input
                  type="number"
                  invalid={invalid}
                  error={error}
                  onBlur={onBlur}
                  placeholder="Number"
                  title="Line Number"
                  onChange={onChange}
                  value={value || ''}
                  ref={ref}
                />
              )}
            />
          </group>
        </view>
      </view>
      <toolbar data-space="20">
        <div className="button primary large" onClick={handleSubmit}>
          <text>Submit</text>
        </div>
      </toolbar>
    </FormProvider>
  );
};
export default PopupForm;
