import {NavLink} from 'react-router-dom';
import Navigation from './shared/navigation';
import {Text} from '../components/shared/index';

const Footer = () => {
  return (
    <footer data-versioning="light">
      <navigation>
        <wrapper>
          <group data-direction="column" data-position="bottom" data-wrap="no">
            <NavLink to="/home" link="">
              <logo>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="90"
                  height="30"
                  viewBox="0 0 376.781 120"
                >
                  <circle fill="#fff" cx="60" cy="60" r="60"></circle>
                  <path
                    fillOpacity="0.7"
                    fill="#fff"
                    d="M357.905,213.574a59.691,59.691,0,0,0,0,84.852,60.752,60.752,0,0,0,85.474,0Z"
                    transform="translate(-66.594 -196)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M443.379,213.574a60.752,60.752,0,0,0-85.474,0,59.691,59.691,0,0,0,0,84.852Z"
                    transform="translate(-66.594 -196)"
                  ></path>
                  <path
                    fill="#fff"
                    fillOpacity="0.7"
                    d="M210.6,196h120l-120,120V196Z"
                    transform="translate(-66.594 -196)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M210.6,316h120l-120-120V316Z"
                    transform="translate(-66.594 -196)"
                  ></path>
                </svg>
              </logo>
            </NavLink>
            <space tiny=""></space>
            <separator horizontal=""></separator>
            <group data-left="medium">
              <Navigation />
            </group>
          </group>
          <space></space>
          <group data-direction="column">
            <span>
              <Text>CopyRight</Text>
              <Text>{new Date().getFullYear()}</Text>
            </span>
            <Text light="">OKC</Text>
          </group>
          <space></space>
          <group data-left="small">
            <a
              href="https://www.facebook.com/OgmaKnowledgeCenter"
              rel="noreferrer"
              target="_blank"
              effect="material"
              className="button mini rounded"
            >
              <icon>
                <svg width="24" height="24px" viewBox="0 0 24 24">
                  <path
                    fill="#fff"
                    d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M18,5H15.5A3.5,3.5 0 0,0 12,8.5V11H10V14H12V21H15V14H18V11H15V9A1,1 0 0,1 16,8H18V5Z"
                  ></path>
                </svg>
              </icon>
            </a>
            <a
              href="https://www.linkedin.com/company/ogma-knowledge-center"
              rel="noreferrer"
              target="_blank"
              effect="material"
              className="button mini rounded"
            >
              <icon>
                <svg width="24px" height="24px" viewBox="0 0 24 24">
                  <path
                    fill="#fff"
                    d="M21,21H17V14.25C17,13.19 15.81,12.31 14.75,12.31C13.69,12.31 13,13.19 13,14.25V21H9V9H13V11C13.66,9.93 15.36,9.24 16.5,9.24C19,9.24 21,11.28 21,13.75V21M7,21H3V9H7V21M5,3A2,2 0 0,1 7,5A2,2 0 0,1 5,7A2,2 0 0,1 3,5A2,2 0 0,1 5,3Z"
                  ></path>
                </svg>
              </icon>
            </a>
          </group>
        </wrapper>
      </navigation>
    </footer>
  );
};

export default Footer;
