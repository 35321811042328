export const ScrollPage = ({
  dataSlide,
  noBackground,
  wrapperSpaceSide,
  ...rest
}) => {
  const props = {};
  const wrapperProps = {};

  dataSlide && (props["data-slide"] = dataSlide);
  wrapperSpaceSide && (wrapperProps["space-side"] = wrapperSpaceSide);

  return (
    <page {...props} {...rest}>
      {!noBackground && (
        <back>
          <picture></picture>
        </back>
      )}

      <wrapper {...wrapperProps}>{rest.children}</wrapper>
    </page>
  );
};
