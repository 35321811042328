import classNames from "classnames";
import { useState } from "react";
export const useMaterialEffect = ({ ref, isMaterial }) => {
  const [circles, setCircles] = useState([]);
  let onMouseDown, onEffectEnd;

  if (isMaterial) {
    onMouseDown = (e) => {
      let current = ref?.current;
      if (current) {
        let clientRect = current.getBoundingClientRect(),
          mouseX = e.pageX - (clientRect.left + document.body.scrollLeft),
          mouseY = e.pageY - (clientRect.top + document.body.scrollTop),
          radius = Math.sqrt(
            Math.pow(current.offsetWidth, 2) + Math.pow(current.offsetHeight, 2)
          );

        if (ref?.current) {
          const newCircle = {
            key: Date.now(),
            classNames: [],
            value: (
              <circle
                r={radius}
                cx={mouseX}
                cy={mouseY}
                style={{
                  transformOrigin: `${mouseX}px ${mouseY}px`,
                  fill: "rgba(0,0,0,.15)",
                }}
              ></circle>
            ),
          };

          setCircles((c) => {
            return [...c, newCircle];
          });

          setTimeout(() => {
            if (ref?.current) {
              setCircles((prev) =>
                prev.map((c) => {
                  return c.Key === newCircle.key
                    ? { ...c, classNames: [c.classNames, "ready"] }
                    : c;
                })
              );
            }
          }, 325);
        }
      }
    };

    onEffectEnd = () => {
      let removingCircles = circles;

      setCircles((prev) => {
        return prev.length
          ? prev.map((c) => ({
              ...c,
              classNames: c.classNames.includes("hide")
                ? c.classNames
                : [c.classNames, "hide"],
            }))
          : prev;
      });

      setTimeout(() => {
        if (ref?.current) {
          setCircles((prev) =>
            prev.length
              ? [
                  ...prev.filter(
                    (currentCircle) =>
                      !removingCircles.some((removing) => {
                        if (removing.key === currentCircle.key) {
                          return true;
                        }
                      })
                  ),
                ]
              : prev
          );
        }
      }, 1000);
    };
  }
  return {
    onMouseDown,
    onMouseUp: onEffectEnd,
    onMouseLeave: onEffectEnd,
    svg: circles.length > 0 && (
      <>
        {circles.map((c) => (
          <svg
            key={c.key}
            className={classNames("material_ink", ...c.classNames)}
          >
            {c.value}
          </svg>
        ))}
      </>
    ),
  };
};
