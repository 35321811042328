import MainHeader from '../components/mainHeader';
import Footer from '../components/footer';
import Navigation from '../components/shared/navigation';
import {useState, useCallback, useEffect} from 'react';
import {Text} from '../components/shared';
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import ScrollToTop from '../utils/helpers/scrollToTop';
import {useTranslation} from 'react-i18next';
import {defaultLang} from '../pages';

const Languages = ['en', 'hy'];

const Layout = (props) => {
  const [isOpened, setIsOpened] = useState(false);
  const {lang} = useParams();
  const {i18n} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (Languages.includes(lang)) {
      if (i18n.language !== lang) {
        i18n.changeLanguage(lang);
      }
    } else {
      let path = location.pathname.slice(location.pathname.lastIndexOf('/'));
      navigate(
        `/${i18n.language || defaultLang}` +
          path +
          location.search +
          location.hash
      );
    }
  }, [i18n, lang, location.pathname, location.hash, navigate, location.search]);

  const handleMenuClick = useCallback(() => {
    const menuElement = document.getElementById('menu');

    if (isOpened) {
      setIsOpened(!isOpened);

      document.documentElement.classList.remove('open_nav');

      menuElement.classList.remove('open');
    } else {
      setIsOpened(!isOpened);

      document.documentElement.classList.add('open_nav');

      menuElement.classList.add('open');
    }
  }, [isOpened]);

  return (
    <>
      <ScrollToTop />
      <Link
        to={`/${i18n.language}/donate`}
        className="button primary donate large"
        outline="none"
      >
        <Text>DonateOKC</Text>
      </Link>
      <MainHeader handleMenuClick={handleMenuClick} />
      <div className="mobile_nav">
        <Navigation handleMenuClick={handleMenuClick} />
      </div>
      <application>
        <Outlet />
      </application>
      <Footer />
    </>
  );
};

export default Layout;
