import { Text } from "../../components/shared/index";

const Apply = () => {
  return (
    <view data-page="apply">
      <section data-versioning="light">
        <back data-background></back>
        <wrapper>
          <space></space>
          <Text
            dataSize="huge"
            dataTransform="lowercase"
            dataWeight="700"
            dataWord="break-word"
          >
            ApplyPage.Apply
          </Text>
          <space></space>
        </wrapper>
      </section>
      <space></space>
      <section>
        <wrapper>
          <Text dataSize="medium">ApplyPage.Desc_1</Text>

          <space></space>
          <Text dataSize="medium">ApplyPage.Desc_2</Text>

          <space></space>
          <space></space>
          <group data-wrap="adaptive" data-type="response">
            <group
              data-direction="column"
              data-width="auto"
              data-top="minus-small"
            >
              <icon extralarge="" data-color="accent">
                receipt_long
              </icon>
              <space medium="" data-display="mobile"></space>
            </group>
            <space width="middle"></space>
            <Text dataSize="medium">ApplyPage.Desc_3</Text>
          </group>
          <space></space>

          <Text dataSize="medium">ApplyPage.Desc_4</Text>
        </wrapper>
      </section>
      <section>
        <wrapper>
          <separator horizontal=""></separator>
        </wrapper>
      </section>
      <section>
        <wrapper>
          <text>
            <Text dataSize="medium" dataWeight="700" dataColor="accent">
              ApplyPage.Desc_5.Part_1
            </Text>
            <a data-type="mail" color="" href="mailto:apply@ogmaknowledgecenter.com">
            <Text dataSize="large" dataWeight="700">
              ApplyPage.Desc_5.Part_2
            </Text>
            </a>
            <Text dataSize="medium" dataWeight="700" dataColor="accent">
              ApplyPage.Desc_5.Part_3
            </Text>
          </text>
      
          <space></space>
          <space></space>
          <a
            className="button flexible large"
            href="mailto:apply@ogmaknowledgecenter.com"
            outline="none"
          >
            <Text>Email</Text>
          </a>
        </wrapper>
      </section>
      <space></space>
    </view>
  );
};

export default Apply;
