import { useMemo, useState } from 'react';
import { FeedbackItem } from '../../components/shared/templates';
import { useEffect } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Text } from '../../components/shared';
import { useHashFragment } from '../../utils/hooks/useHashFragment';

const FeedbacksGrid = () => {
  const [feedbacks, setFeedbacks] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);
  useHashFragment(260);

  const filteredKeys = useMemo(() => {
    if (!feedbacks) {
      return [];
    }
    return Object.keys(feedbacks).filter((key) => feedbacks[key].feedback);
  }, [feedbacks]);

  useEffect(() => {
    const db = getDatabase();
    const starCountRef = ref(db, 'students');
    onValue(starCountRef, (snapshot) => {
      let result = snapshot.val();
      setFeedbacks(result);
    });
  }, []);

  return (
    <>
      {filteredKeys.length > 0 && (
        <>
          <div
            className={classNames('cover', {
              expand: isExpanded || filteredKeys.length <= 4,
            })}
          >
            <Text id="feedbacks" dataSize="x-large" dataWeight="700" dataColor="accent" dataWord="break-word">
              Home.StudentsFeedback
            </Text>
            <space medium=""></space>

            {filteredKeys.map((key, index) => {
              const item = feedbacks[key];
              item.key = key;

              return <FeedbackItem data={item} key={key} isLastItem={index === filteredKeys.length - 1} />;
            })}

            <space large=""></space>
          </div>
          <space></space>
          <space></space>
          {filteredKeys.length > 4 && (
            <>
              <group data-justify="center">
                <div
                  className="expand"
                  onClick={() => {
                    setIsExpanded(!isExpanded);
                  }}
                >
                  <text data-size="medium" expand="" data-weight="700">
                    {isExpanded ? <Text>Community.ShowLess</Text> : <Text>Community.ShowMore</Text>}
                  </text>
                </div>
              </group>
              <space></space>
              <space></space>
            </>
          )}
        </>
      )}
    </>
  );
};
export default FeedbacksGrid;
