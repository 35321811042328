import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './i18n';
import { BrowserRouter } from 'react-router-dom';
import { isMobile, isDesktop } from 'react-device-detect';
import AppRoutes from './pages';

import store from './store';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';

/*eslint no-extend-native: ["error", { "exceptions": ["Object"] }]*/
var _toString = Object.prototype.toString;
Object.prototype.toString = function () {
  try {
    switch (this.tagName) {
      case 'CIRCLE':
      case 'CONT':
      case 'DECOR':
      case 'TEXT':
      case 'ICON':
      case 'BACK':
      case 'BORDER':
      case 'PAGE':
      case 'WRAPPER':
      case 'TOOLBAR':
      case 'SLIDE':
      case 'ITEM':
      case 'GRID':
      case 'INDICATOR':
      case 'NAVIGATOR':
      case 'SWITCH':
      case 'HAMBURGER':
      case 'NAVIGATION':
      case 'GROUP':
      case 'LOGO':
      case 'LINE':
      case 'SEPARATOR':
      case 'WRAP':
      case 'SPACE':
      case 'CONTAINER':
      case 'BADGE':
      case 'APPLICATION':
      case 'VIEW':
      case 'THUMB':
      case 'MEDIA':
      case 'CHECK':
      case 'INK':
      case 'BOX':
        return '[object div]';
      default:
        break;
    }
  } catch {}
  return _toString.call(this);
};

isMobile && document.documentElement.classList.add('mobile');
isDesktop && document.documentElement.classList.add('desktop');

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <BrowserRouter basename={baseUrl}>
      <ConfigProvider
        theme={{
          token: {
            borderRadius: 5,
            colorPrimary: '#b03a3b',
            sizeStep: 5,
            sizeUnit: 5,
            fontSize: 13,
            fontFamily: 'Plus Jakarta Sans',
            fontSizeLG: 13,
            paddingXXS: 5,
            marginXXS: 5,
            colorLink: '#b03a3b',
            colorLinkHover: '#ff705e4a',
          },
        }}
      >
        <AppRoutes />
      </ConfigProvider>
    </BrowserRouter>
  </Provider>
);
