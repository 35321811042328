import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import Draggable from 'react-draggable';
import { useDispatch } from 'react-redux';
import { addPopup, removePopup } from '../../store/componentsSlice';
import Button from './button';

export const popupRoot = document.getElementById('popup-root');

const Popup = ({
  fullscreen = false,
  noHeader = false,
  onClose,
  windowContProps,
  children,
  title = '',
  fixSize,
  coloredTitle = false,
}) => {
  const dispatch = useDispatch();
  const [isMount, setIsMount] = useState(false);
  const containerRef = useRef(null);

  const elementRef = useRef(null);

  useEffect(() => {
    setIsMount(true);
    dispatch(addPopup());

    return () => {
      dispatch(removePopup());
      setIsMount(false);
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    document.body.classList[isMount ? 'add' : 'remove']('lock_scroll');

    return () => {
      document.body.classList.remove('lock_scroll');
    };
  }, [isMount]);

  let innerContent = (
    <div ref={elementRef}>
      {!noHeader && (
        <toolbar data-space="20" {...(coloredTitle ? { 'data-accent': '' } : null)}>
          <group data-wrap="no" data-align="center">
            <text data-weight="700" data-space="10" data-ellipsis="">
              {title}
            </text>
            <Button
              mini
              rounded
              material
              {...(fullscreen ? null : { position: 'right' })}
              className="close"
              icon={fullscreen ? 'chevron_left' : 'close'}
              onClick={onClose}
            />
          </group>

          {/*<text data-weight="700" space="">*/}
          {/*    {t(title)}*/}
          {/*</text>*/}
        </toolbar>
      )}
      <div>
        <div
          id="windowContent"
          className="window_cont"
          {...(windowContProps ? windowContProps : null)}
          fixed="medium"
        >
          {isMount && children}
        </div>
      </div>
    </div>
  );
  if (!fullscreen) {
    innerContent = (
      <Draggable
        nodeRef={elementRef}
        cancel=".window_cont,.close"
        bounds="parent"
      >
        {innerContent}
      </Draggable>
    );
  }

  return createPortal(
    <div
      ref={containerRef}
      className={classNames('popup', 'open', { fullscreen })}
    >
      {innerContent}
    </div>,
    popupRoot
  );
};

export default Popup;
