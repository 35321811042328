import { createSlice } from '@reduxjs/toolkit';
import { getLocalStorageItem, setLocalStorageItem } from '../utils/helpers/localStorage';

const defaultState = {
  accessToken: '',
  displayName: '',
  email: '',
  emailVerified: false,
  isAnonymous: false,
  providerId: '',
  uid: '',
  tenantId: '',
};

const setData = (target, source, reset) => {
  const result = {};
  Object.keys(defaultState).forEach((k) => {
    if (source[k]) {
      target[k] = source[k];
      result[k] = source[k];
    } else if (reset) {
      target[k] = undefined;
    }
  });
  return result;
};

const loadStateFromStorage = (state) => {
  const auth = getLocalStorageItem('auth');
  if (auth) {
    const newState = auth ? JSON.parse(auth) : { ...defaultState };
    setData(state, newState, true);
  }
};

let initialState = defaultState;
loadStateFromStorage(initialState);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserData(state, payload) {
      const result = setData(state, payload.payload, true);
      setLocalStorageItem('auth', JSON.stringify(result));
    },
    clearUserData(state) {
      setLocalStorageItem('auth', JSON.stringify({}));
      setData(state, {}, true);
    },
  },
});

export const { setUserData, clearUserData } = authSlice.actions;
export default authSlice.reducer;
