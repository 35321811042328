import { Route, Navigate, Routes } from 'react-router-dom';
import About from './about';
import Home from './home';
import Programs from './programs';
import Community from './community';
import Apply from './apply';
import Contact from './contact';
import Donate from './donate';
import Layout from '../layout';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Login from './login';
import { useDispatch, useSelector } from 'react-redux';
import { clearMessages } from '../store/componentsSlice';
import { message } from 'antd';

export const defaultLang = 'hy';

const AppRoutes = () => {
  const { i18n } = useTranslation();
  const userUid = useSelector((s) => s.auth.uid);
  const dispatch = useDispatch();
  const messagesData = useSelector((state) => state.components.messages);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    const width = window.innerWidth - document.documentElement.clientWidth;
    document.body.style.setProperty('--scroll_width', width + 'px');
  }, []);

  useEffect(() => {
    if (messagesData.length) {
      messagesData.forEach((m) => {
        const { type, message } = m;
        messageApi[type](message);
      });

      dispatch(clearMessages());
    }
  }, [messagesData]);

  return (
    <>
      {contextHolder}
      <Routes>
        <Route path="/:lang" element={<Layout />}>
          <Route index element={<Navigate to="home" replace />} />
          <Route path="home" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="programs" element={<Programs />} />
          <Route path="community" element={<Community />} />
          <Route path="apply" element={<Apply />} />
          <Route path="contacts" element={<Contact />} />
          <Route path="donate" element={<Donate />} />
        </Route>
        {!userUid && <Route path="login" element={<Login />} />}
        <Route path="*" element={<Navigate to={`${i18n.language || defaultLang}/home`} replace={true} />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
