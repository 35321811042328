import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function useHashFragment(offset = 0, trigger = true) {
  const { hash } = useLocation();

  useEffect(() => {
    const scrollToHashElement = () => {
      setTimeout(() => {
        const elementToScroll = document.getElementById(hash?.replace('#', ''));
        if (!elementToScroll) return;
        let parent = elementToScroll,
          topOffset = 0;

        while (parent.parentElement.id !== 'root') {
          topOffset += parent.offsetTop;
          parent = parent.parentElement;
        }
        console.log('hash');
        window.scrollTo({
          top: topOffset - offset,
          behavior: 'smooth',
        });
      }, 100);
    };

    if (!trigger) return;

    scrollToHashElement();
    window.addEventListener('hashchange', scrollToHashElement);
    return window.removeEventListener('hashchange', scrollToHashElement);
  }, [trigger, hash]);
}
