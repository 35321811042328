import React from 'react';

const Input = React.forwardRef(({ type, title, placeholder, onChange, onBlur, value, required }, ref) => {
  return (
    <div className="field">
      <div className="form_fields">
        <div className="data_label">{title}</div>
        <div className="field_cont">
          <input type={type} onBlur={onBlur} onChange={onChange} value={value} placeholder={placeholder} ref={ref} />
          <i></i>
        </div>
      </div>
    </div>
  );
});

export default Input;

export const TextArea = React.forwardRef(({ title, placeholder, onChange, onBlur, value }, ref) => {
  return (
    <div className="field">
      <div className="form_fields">
        <div className="data_label">{title}</div>
        <div className="field_cont">
          <textarea
            resize=""
            aria-invalid="false"
            spellCheck="false"
            onBlur={onBlur}
            onChange={onChange}
            placeholder={placeholder}
            value={value}
            ref={ref}
          />
          <i></i>
        </div>
      </div>
    </div>
  );
});
