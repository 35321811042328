import { Text } from "../../components/shared/index";

const Donate = () => {
  return (
    <>
    <view data-page="donate">
      <section data-versioning="light">
        <back data-background="true"></back>
        <wrapper>
          <space></space>
          <Text
              dataSize="huge"
              dataTransform="lowercase"
              dataWeight="700"
              dataWord="break-word"
            >
              DonationPage.Caption
            </Text>
          <space></space>
        </wrapper>
      </section>
      <section>
        <wrapper>
          <space></space>
          <Text data-size="medium" data-color="accent" data-weight="500">DonationPage.Part_1</Text>
          <space small=""></space>
          <Text data-size="medium" data-color="accent" data-weight="500" dataWidth="huge">
            DonationPage.Part_2
          </Text>
          <space></space>
          <space tiny=""></space>
          <Text data-size="medium" dataWeight="700">
            DonationPage.Part_3
          </Text>
          <space tiny=""></space>
          <group data-display="list_item" data-direction="column">
            <wrapper data-gap="small">
              <Text data-size="medium">DonationPage.Part_4</Text>
              <Text data-size="medium">DonationPage.Part_5</Text>
              <Text data-size="medium">DonationPage.Part_6</Text>
            </wrapper>
          </group>
          <space tiny=""></space>
          <Text data-size="medium" data-color="accent" data-weight="500" dataWidth="huge">
            DonationPage.Part_7
          </Text>
          <space></space>
          <separator horizontal=""></separator>
          <space></space>
          <Text data-size="medium" dataWeight="700">
            DonationPage.Part_8
          </Text>
          <space></space>
          <space></space>
          <grid data-type="donate">
            <item>
                <Text data-color="accent" dataWeight="700" data-size="large">
                  DonationPage.Bank.Part_1
                </Text>
                <space></space>
                <Text data-size="small">DonationPage.Bank.Part_2</Text>
                <Text data-size="small">DonationPage.Bank.Part_3</Text>
                <Text data-size="small">DonationPage.Bank.Part_4</Text>
                <Text data-size="small">DonationPage.Bank.Part_5</Text>
                <Text data-size="small">DonationPage.Bank.Part_6</Text>
                <Text data-size="small">DonationPage.Bank.Part_7</Text>

            </item>
            <item>
                <Text data-color="accent" dataWeight="700" data-size="large">
                  DonationPage.Bank.Part_8
                </Text>
                <space></space>
                <Text data-size="small">DonationPage.Bank.Part_9</Text>
                <Text data-size="samll">DonationPage.Bank.Part_10</Text>
                <Text data-size="small">DonationPage.Bank.Part_11</Text>
                <Text data-size="small">DonationPage.Bank.Part_12</Text>
                <Text data-size="small">DonationPage.Bank.Part_13</Text>
                <Text data-size="small">DonationPage.Bank.Part_14</Text>
            </item>
            <item>
                <Text data-color="accent" dataWeight="700" data-size="large">
                  DonationPage.Bank.Part_15
                </Text>
                <space></space>
                <Text data-size="small">DonationPage.Bank.Part_16</Text>
                <Text data-size="small">DonationPage.Bank.Part_17</Text>
                <Text data-size="small">DonationPage.Bank.Part_18</Text>
            </item>
          </grid>
          <space></space>
          <space></space>
          <Text data-size="x-large" dataWeight="700">
            DonationPage.Bank.Part_19
          </Text>
          <space></space>
          <space></space>
        </wrapper>
      </section>
    </view>
    </>
  );
};

export default Donate;
