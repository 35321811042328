import { NavLink, useNavigate } from 'react-router-dom';
import LanguageSwitch from './languageSwitch';
import Navigation from '../components/shared/navigation';
import { useCallback, useEffect, useState } from 'react';
import { signOut } from '@firebase/auth';
import { auth } from '../firebase';
import { clearUserData } from '../store/authSlice';
import { useDispatch, useSelector } from 'react-redux';

const MainHeader = (props) => {
  const dispatch = useDispatch();
  const userId = useSelector((s) => s.auth.uid);
  const navigate = useNavigate();
  const [stuck, setClassName] = useState('');

  const scrollListener = useCallback(() => {
    setClassName(window.scrollY >= 40 ? 'stuck' : '');
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', scrollListener);

    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, [scrollListener]);

  return (
    <navigation className={stuck} data-backdrop="">
      <wrapper>
        <group data-align="center" data-position="center" data-wrap="no">
          <NavLink to="/home" link="">
            <logo data-width="medium">
              <svg xmlns="http://www.w3.org/2000/svg" width="90" height="30" viewBox="0 0 376.781 120">
                <circle fill="#f99a22" cx="60" cy="60" r="60"></circle>
                <path
                  fill="#483977"
                  d="M357.905,213.574a59.691,59.691,0,0,0,0,84.852,60.752,60.752,0,0,0,85.474,0Z"
                  transform="translate(-66.594 -196)"
                ></path>
                <path
                  fill="#783575"
                  d="M443.379,213.574a60.752,60.752,0,0,0-85.474,0,59.691,59.691,0,0,0,0,84.852Z"
                  transform="translate(-66.594 -196)"
                ></path>
                <path fill="#b03a3b" d="M210.6,196h120l-120,120V196Z" transform="translate(-66.594 -196)"></path>
                <path fill="#ea5f47" d="M210.6,316h120l-120-120V316Z" transform="translate(-66.594 -196)"></path>
              </svg>
            </logo>
          </NavLink>
          <group data-position="right" data-length="auto" data-display="desk">
            <Navigation />
          </group>
          <group data-position="right" data-display="mobile"></group>
          <div id="menu" className="button mini rounded" data-display="mobile" onClick={props.handleMenuClick}>
            <icon>
              <switch>
                <hamburger></hamburger>
                <hamburger></hamburger>
                <hamburger></hamburger>
              </switch>
            </icon>
          </div>
          <separator vertical=""></separator>
          <LanguageSwitch />
          {userId && (
            <>
              <separator vertical=""></separator>
              <div
                className="button rounded"
                onClick={() => {
                  signOut(auth)
                    .then(() => {
                      dispatch(clearUserData());
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }}
              >
                <text>Sign Out</text>
              </div>
            </>
          )}
        </group>
      </wrapper>
    </navigation>
  );
};

export default MainHeader;
