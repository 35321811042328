import {Text} from '../../components/shared/index';
import 'yet-another-react-lightbox/styles.css';
import {useHashFragment} from '../../utils/hooks/useHashFragment';
import {Trans, useTranslation} from 'react-i18next';

const About = () => {
  useHashFragment(260);
  const {t} = useTranslation();

  return (
    <view data-page="about">
      <section>
        <back data-background=""></back>
        <wrapper data-versioning="light">
          <space></space>
          <Text
            dataTransform="lowercase"
            dataSize="huge"
            dataWeight="700"
            dataWord="break-word"
          >
            AboutUs
          </Text>
          <space></space>
          <space tiny=""></space>
          <Text dataSize="medium" dataWidth="medium" dataWeight="700">
            About.Intro_1
          </Text>
          <space></space>
          <Text dataSize="medium" dataWidth="large">
            About.Intro_2
          </Text>
          <space></space>
        </wrapper>
      </section>
      <space></space>
      <section>
        <wrapper>
          <Text
            dataSize="x-large"
            dataWeight="700"
            dataColor="accent-dark"
            dataWord="break-word"
          >
            About.OurGoals
          </Text>
          <space></space>
          <space></space>
          <Text dataSize="medium">About.GoalsDesc</Text>
          <space></space>
          <space></space>
          <Text dataIndex="1" dataSize="large" dataWeight="700">
            About.ValuesWeShare.Desc
          </Text>
          <space tiny=""></space>
          <group data-display="list_item" data-direction="column">
            <wrapper data-gap="small">
              <Text dataSize="medium">About.ValuesWeShare.WeShare1</Text>
              <Text dataSize="medium">About.ValuesWeShare.WeShare2</Text>
              <Text dataSize="medium">About.ValuesWeShare.WeShare3</Text>
              <Text dataSize="medium">About.ValuesWeShare.WeShare4</Text>
              <Text dataSize="medium">About.ValuesWeShare.WeShare5</Text>
            </wrapper>
          </group>
        </wrapper>
      </section>
      <section>
        <back style={{background: '#b03a3b'}}></back>
        <space></space>
        <wrapper data-versioning="light">
          <Text dataSize="x-large" dataWeight="700" dataWord="break-word">
            About.OurHistory
          </Text>
          <space></space>
          <space></space>
          <grid data-type="collage">
            <item>
              <picture>
                <img src="../content/img/res_8.jpg" alt="" />
              </picture>
            </item>
            <item className="large">
              <picture>
                <img src="../content/img/res_12.jpg" alt="" />
              </picture>
            </item>
            <item>
              <picture>
                <img src="../content/img/res_10.jpg" alt="" />
              </picture>
            </item>
            <item>
              <picture>
                <img src="../content/img/res_9.jpg" alt="" />
              </picture>
            </item>
            <item>
              <picture>
                <img src="../content/img/res_7.jpg" alt="" />
              </picture>
            </item>
          </grid>
          <space></space>
          <space></space>
          <Text dataSize="medium" dataColumn="3" dataLine="pre-line">
            About.History
          </Text>
        </wrapper>
        <space></space>
        <space></space>
      </section>
      <space></space>
      {/* <wrapper>
        <separator horizontal=""></separator>
      </wrapper>
      <section>
        <wrapper>
          <grid data-type="gallery">
            <item onClick={handleImageClick}>
              <picture>
                <img src="../content/img/res_2.jpg" alt="" />
              </picture>
            </item>
            <item onClick={handleImageClick}>
              <picture>
                <img src="../content/img/res_1.jpg" alt="" />
              </picture>
            </item>
            <item onClick={handleImageClick}>
              <picture>
                <img src="../content/img/res_3.jpg" alt="" />
              </picture>
            </item>
            <item onClick={handleImageClick}>
              <picture>
                <img src="../content/img/res_1.jpg" alt="" />
              </picture>
            </item>
            <item onClick={handleImageClick}>
              <picture>
                <img src="../content/img/res_2.jpg" alt="" />
              </picture>
            </item>
            <item onClick={handleImageClick}>
              <picture>
                <img src="../content/img/res_1.jpg" alt="" />
              </picture>
            </item>
            <item onClick={handleImageClick}>
              <picture>
                <img src="../content/img/res_3.jpg" alt="" />
              </picture>
            </item>
            <item onClick={handleImageClick}>
              <picture>
                <img src="../content/img/res_1.jpg" alt="" />
              </picture>
            </item>
            <item>
              <picture>
                <img src="../content/img/res_3.jpg" alt="" />
              </picture>
            </item>
            <item>
              <picture>
                <img src="../content/img/res_1.jpg" alt="" />
              </picture>
            </item>
          </grid>
        </wrapper>
      </section>
      <wrapper>
        <separator horizontal=""></separator>
      </wrapper> */}
      <section data-overflow="hidden">
        <wrapper>
          <Text
            dataSize="x-large"
            dataWeight="700"
            dataColor="accent-dark"
            dataWord="break-word"
          >
            About.Principles.Desc
          </Text>
          <space></space>
          <space></space>
          <Text
            dataSize="large"
            dataWeight="700"
            dataColor="accent"
            dataWord="break-word"
          >
            About.Principles.Title_1
          </Text>
          <space></space>
          <space></space>
          <group data-flex="initial" data-column="3" id="principleSection1">
            <group
              data-element="after"
              data-align="center"
              data-justify="center"
            >
              <icon huge="">handshake</icon>
            </group>
            <space></space>
            <Text dataSize="medium" dataLine="pre-line">
              About.Principles.Desc_1
            </Text>
          </group>
        </wrapper>
      </section>
      <wrapper>
        <separator horizontal=""></separator>
      </wrapper>
      <section data-overflow="hidden">
        <wrapper>
          <Text
            dataSize="large"
            dataWeight="700"
            dataColor="accent"
            dataWord="break-word"
          >
            About.Principles.Title_2
          </Text>
          <space></space>
          <space></space>
          <group data-flex="initial" data-column="4" id="principleSection2">
            <Text dataSize="medium" dataLine="pre-line">
              About.Principles.Desc_2
            </Text>
            <space></space>
            <thumb data-thumb="medium" rounded="">
              <picture>
                <img src="../content/img/res_15.jpg" alt="" />
              </picture>
            </thumb>
          </group>
        </wrapper>
      </section>

      <wrapper>
        <separator horizontal=""></separator>
      </wrapper>
      <section data-overflow="hidden">
        <wrapper>
          <Text
            dataSize="large"
            dataWeight="700"
            dataColor="accent"
            dataWord="break-word"
          >
            About.Principles.Title_3
          </Text>
          <space></space>
          <space></space>
          <group data-flex="initial" data-column="3" id="principleSection4">
            <group
              data-element="after"
              data-align="center"
              data-justify="center"
              data-bottom="medium"
            >
              <icon huge="">school</icon>
            </group>
            <Text dataSize="medium" dataLine="pre-line">
              About.Principles.Desc_3
            </Text>
          </group>
        </wrapper>
      </section>

      <wrapper>
        <separator horizontal=""></separator>
      </wrapper>
      <section data-overflow="hidden">
        <wrapper>
          <Text
            dataSize="large"
            dataWeight="700"
            dataColor="accent"
            dataWord="break-word"
          >
            About.Principles.Title_4
          </Text>
          <space></space>
          <space></space>
          <group data-flex="initial" data-column="3">
            <Text dataSize="medium" dataLine="pre-line">
              About.Principles.Desc_4
            </Text>
            <group
              data-element="after"
              data-align="center"
              data-justify="center"
              data-top="medium"
            >
              <icon huge="">apartment</icon>
            </group>
          </group>
        </wrapper>
      </section>

      <wrapper>
        <separator horizontal=""></separator>
      </wrapper>
      <section data-overflow="hidden">
        <wrapper>
          <Text
            dataSize="large"
            dataWeight="700"
            dataColor="accent"
            dataWord="break-word"
          >
            About.Principles.Title_5
          </Text>
          <space></space>
          <space></space>
          <group data-flex="initial" data-column="3">
            <group
              data-element="after"
              data-align="center"
              data-justify="center"
              data-bottom="medium"
            >
              <icon huge="">auto_stories</icon>
            </group>
            <Text dataSize="medium" dataLine="pre-line">
              About.Principles.Desc_5
            </Text>
          </group>
        </wrapper>
      </section>

      <wrapper>
        <separator horizontal=""></separator>
      </wrapper>
      <section data-overflow="hidden" id="principleSection3">
        <wrapper>
          <Text
            dataSize="large"
            dataWeight="700"
            dataColor="accent"
            dataWord="break-word"
          >
            About.Principles.Title_6
          </Text>
          <space></space>
          <space></space>
          <group data-flex="initial" data-column="3">
            <Text dataSize="medium" dataLine="pre-line">
              About.Principles.Desc_6
            </Text>
            <group
              data-element="after"
              data-align="center"
              data-justify="center"
              data-top="medium"
            >
              <icon huge="">timeline</icon>
            </group>
          </group>
        </wrapper>
      </section>

      <wrapper>
        <separator horizontal=""></separator>
      </wrapper>
      <section data-overflow="hidden">
        <wrapper>
          <Text
            dataSize="large"
            dataWeight="700"
            dataColor="accent"
            dataWord="break-word"
          >
            About.Principles.Title_7
          </Text>
          <space></space>
          <space></space>
          <group data-flex="initial" data-column="4">
            <thumb data-thumb="medium" data-bottom="medium" rounded="">
              <picture>
                <img src="../content/img/res_14.jpg" alt="" />
              </picture>
            </thumb>
            <Text dataSize="medium" dataLine="pre-line">
              About.Principles.Desc_7
            </Text>
          </group>
        </wrapper>
      </section>

      <wrapper>
        <separator horizontal=""></separator>
      </wrapper>
      <section data-overflow="hidden">
        <wrapper>
          <Text
            dataSize="large"
            dataWeight="700"
            dataColor="accent"
            dataWord="break-word"
          >
            About.Principles.Title_8
          </Text>
          <space></space>
          <space></space>
          <group data-flex="initial" data-column="3">
            <Text dataSize="medium" dataLine="pre-line">
              About.Principles.Desc_8
            </Text>
            <group
              data-element="after"
              data-align="center"
              data-justify="center"
              data-top="medium"
            >
              <icon huge="">diversity_3</icon>
            </group>
          </group>
        </wrapper>
      </section>

      <wrapper>
        <separator horizontal=""></separator>
      </wrapper>
      <section data-overflow="hidden">
        <wrapper>
          <Text
            dataSize="large"
            dataWeight="700"
            dataColor="accent"
            dataWord="break-word"
          >
            About.Principles.Title_9
          </Text>
          <space></space>
          <space></space>
          <group data-flex="initial" data-column="3">
            <group
              data-element="after"
              data-align="center"
              data-justify="center"
              data-bottom="medium"
            >
              <icon huge="">history_edu</icon>
            </group>
            <Text dataSize="medium" dataLine="pre-line">
              About.Principles.Desc_9
            </Text>
          </group>
        </wrapper>
      </section>
      <space></space>
      <wrapper>
        <Text data-size="medium">About.Principles.Desc_10</Text>
      </wrapper>
      <wrapper>
        <separator horizontal=""></separator>
      </wrapper>

      <section>
        <wrapper>
          <Text
            id="okcNumbers"
            dataSize="x-large"
            data-color="accent-dark"
            dataWeight="700"
            dataWord="break-word"
          >
            About.Numbers_OKC.Desc
          </Text>
          <space></space>
          <space></space>
          <grid data-type="number">
            <item>
              <back style={{background: '#f99a22'}}></back>
              <group data-versioning="light">
                <text data-size="x-large" data-weight="700">
                  <Trans
                    t={t}
                    i18nKey="About.Numbers_OKC.Section_1"
                    values={{
                      ten: new Date().getFullYear() - 2013,
                      fourhundred: '400',
                    }}
                    components={[<text />]}
                  />
                </text>
              </group>
            </item>
            <item>
              <back style={{background: '#ea5f47'}}></back>
              <group data-versioning="light">
                <text data-size="x-large" data-weight="700">
                  <Trans
                    t={t}
                    i18nKey="About.Numbers_OKC.Section_2"
                    values={{ninety: '90'}}
                    components={[<text />]}
                  />
                </text>
              </group>
            </item>
            <item>
              <back style={{background: '#298eae'}}></back>
              <group data-versioning="light">
                <text data-size="x-large" data-weight="700">
                  <Trans
                    t={t}
                    i18nKey="About.Numbers_OKC.Section_3"
                    values={{fourteen: '14', fivetee: '55'}}
                    components={[<text />]}
                  />
                </text>
              </group>
            </item>
          </grid>
          <space></space>
        </wrapper>
      </section>
      <section>
        {/* <wrapper data-align="center">
          <a href className="button large rounded accent" outline="none">
            <Text>LearnMore</Text>
            <icon>arrow_right_alt</icon>
          </a>
        </wrapper> */}
      </section>

      {/* <space></space>
      <section>
        <wrapper>
          <space></space>
        </wrapper>
      </section>
      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={[
          {
            id: "ts1zXzsD7xc",
            width: 1080,
            height: 1620,
            srcSet: [
              {
                src: "../content/img/res_1.jpg",
                width: 1080,
                height: 1620,
              },
            ],
          },
          {
            id: "F_r83HEzsXI",
            width: 1080,
            height: 1620,
            srcSet: [
              {
                src: "../content/img/res_2.jpg",
                width: 1080,
                height: 1620,
              },
            ],
          },
          {
            id: "m82uh_vamhg",
            width: 1080,
            height: 1620,
            srcSet: [
              {
                src: "../content/img/res_3.jpg",
                width: 1080,
                height: 1620,
              },
            ],
          },
        ]}
      /> */}
    </view>
  );
};

export default About;
